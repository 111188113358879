import { ReactElement, ReactNode, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import {
	Card,
	ChipButton,
	FontSizes,
	Loader,
	PaletteColor,
	PrimaryButton,
	Text,
	TextElements,
} from '@calm-web/design-system';

import CellTitle from '@/components/ui/CellTitle';
import { useFeatureFlags } from '@/hooks/api/useFeatureFlags';
import { useHealthSponsorships } from '@/hooks/api/useHealthSponsorships';
import { usePermissions } from '@/hooks/auth';
import { HealthSponsorship } from '@/types/health';
import { ALLOWED_COUNTRIES } from '@/utils/allowedCountries';
import { dateFromIso8601DateForDatePicker, utcToIso8601Date } from '@/utils/helpers';

import { SponsorshipDescription, EditLink, MetadataWrapper, Wrapper, DescriptionWrapper } from './styles';

export const INTERNATIONAL_CONFIGS_FEATURE_FLAG = 'b2b-ch-international-configs';

const isDefaultSponsorship = (sponsorship: HealthSponsorship): boolean =>
	sponsorship.assignment_rules?.[0]?.operator === 'true';

function HealthSponsorshipMetadataTile({
	description,
	value,
	color = 'text',
	children,
}: {
	description: string;
	value: string;
	color?: PaletteColor;
	children?: ReactNode;
}): ReactElement {
	return (
		<div>
			<Text color="gray5" size={FontSizes.sm} el={TextElements.DescriptionTerm}>
				{description}
			</Text>
			<Text color={color} el={TextElements.DescriptionDetails}>
				{value}
			</Text>
			{children}
		</div>
	);
}

function HealthSponsorshipOverview({
	sponsorship,
	index,
	isInternationalConfigsFlagOn,
}: {
	sponsorship: HealthSponsorship;
	index: number;
	isInternationalConfigsFlagOn: boolean;
}): ReactElement {
	const isDefault = isDefaultSponsorship(sponsorship);
	const [hasValidPermissions, actions] = usePermissions();
	const { activeValue, statusColor, startsAtFormatted, endsAtFormatted } = useMemo(() => {
		const startsAt = new Date(sponsorship.starts_at);
		const startsAtDateOnly = utcToIso8601Date(startsAt);
		const startsAtFormatted = dateFromIso8601DateForDatePicker(startsAtDateOnly).toLocaleDateString(
			undefined,
			{},
		);
		const endsAt = new Date(sponsorship.ends_at);
		const endsAtDateOnly = utcToIso8601Date(endsAt);
		const endsAtFormatted = dateFromIso8601DateForDatePicker(endsAtDateOnly).toLocaleDateString(
			undefined,
			{},
		);
		const now = new Date();
		const activeValue =
			now >= startsAt && now < endsAt
				? 'Active'
				: isInternationalConfigsFlagOn
				? now < startsAt
					? 'Upcoming'
					: 'Expired'
				: 'Inactive';
		const statusColor = activeValue === 'Active' ? 'success' : activeValue === 'Upcoming' ? 'gray5' : 'error';
		return { activeValue, statusColor, startsAtFormatted, endsAtFormatted };
	}, [sponsorship.starts_at, sponsorship.ends_at, isInternationalConfigsFlagOn]);

	const description = sponsorship.description
		? sponsorship.description
		: isDefault
		? 'Default'
		: sponsorship?.assignment_rules?.[0]?.assignment_rule?.attributes
				?.map(rule => {
					return `${rule.type} = ${rule.value}`;
				})
				.join(` ${sponsorship.assignment_rules[0].operator} `);

	return (
		<Wrapper $isDefault={isDefault}>
			<MetadataWrapper>
				<HealthSponsorshipMetadataTile
					description={isDefault ? 'Default' : `Sponsor Group ${index + 1}`}
					value={sponsorship.display_name}
				>
					<DescriptionWrapper>
						{isInternationalConfigsFlagOn ? (
							description ? (
								<SponsorshipDescription>{description}</SponsorshipDescription>
							) : null
						) : sponsorship.description ? (
							<SponsorshipDescription>{sponsorship.description}</SponsorshipDescription>
						) : null}
					</DescriptionWrapper>
				</HealthSponsorshipMetadataTile>
				<HealthSponsorshipMetadataTile description="Start Date" value={startsAtFormatted} />
				<HealthSponsorshipMetadataTile description="End Date" value={endsAtFormatted} />
				<HealthSponsorshipMetadataTile
					description="Status"
					value={activeValue}
					color={statusColor as PaletteColor}
				>
					<Link
						to={`/${sponsorship.partner_id}/account/health-sponsorships/${sponsorship.id}`}
						component={EditLink}
					>
						{hasValidPermissions('health_sponsorship_display_name', [actions.UPDATE])
							? 'Edit Group Details'
							: 'View Group Details'}
					</Link>
				</HealthSponsorshipMetadataTile>
			</MetadataWrapper>
		</Wrapper>
	);
}

function HealthSponsorshipsInner({
	healthSponsorships,
	isInternationalConfigsFlagOn,
}: {
	healthSponsorships: HealthSponsorship[];
	isInternationalConfigsFlagOn: boolean;
}): ReactElement {
	const [showActive, setShowActive] = useState(true);
	const [showUpcoming, setShowUpcoming] = useState(true);
	const [showExpired, setShowExpired] = useState(false);
	const defaultSponsorship = healthSponsorships?.find(
		sponsorship => sponsorship?.assignment_rules?.[0].operator === 'true',
	);

	const activeSponsorsLength = useMemo(() => {
		return healthSponsorships?.filter(sponsorship => {
			if (isDefaultSponsorship(sponsorship)) {
				return false;
			}
			const now = new Date();
			const startsAt = new Date(sponsorship.starts_at);
			const endsAt = new Date(sponsorship.ends_at);
			return now >= startsAt && now < endsAt;
		}).length;
	}, [healthSponsorships]);

	const upcomingSponsorsLength = useMemo(() => {
		return healthSponsorships?.filter(sponsorship => {
			if (isDefaultSponsorship(sponsorship)) {
				return false;
			}
			const now = new Date();
			const startsAt = new Date(sponsorship.starts_at);
			return now < startsAt;
		}).length;
	}, [healthSponsorships]);

	const expiredSponsorsLength = useMemo(() => {
		return healthSponsorships?.filter(sponsorship => {
			if (isDefaultSponsorship(sponsorship)) {
				return false;
			}
			const now = new Date();
			const endsAt = new Date(sponsorship.ends_at);
			return now >= endsAt;
		}).length;
	}, [healthSponsorships]);

	const filterSponsors = (sponsorship: HealthSponsorship): boolean => {
		const now = new Date();
		const startsAt = new Date(sponsorship.starts_at);
		const endsAt = new Date(sponsorship.ends_at);
		if (isDefaultSponsorship(sponsorship)) {
			return false;
		}
		if (!showActive && !showUpcoming && !showExpired) {
			return true;
		}
		if (showActive && now >= startsAt && now < endsAt) {
			return true;
		}
		if (showUpcoming && now < startsAt) {
			return true;
		}
		if (showExpired && now >= endsAt) {
			return true;
		}
		return false;
	};

	return (
		<>
			{defaultSponsorship && (
				<HealthSponsorshipOverview
					sponsorship={defaultSponsorship}
					index={0}
					isInternationalConfigsFlagOn={isInternationalConfigsFlagOn}
				/>
			)}
			Rearrange the order of the following sponsor groups from highest to lowest priority.
			<div style={{ display: 'flex', gap: '8px', margin: '16px 0' }}>
				<ChipButton
					isActive={showActive}
					onPress={() => {
						setShowActive(prev => !prev);
					}}
				>
					Active ({activeSponsorsLength})
				</ChipButton>
				<ChipButton
					isActive={showUpcoming}
					onPress={() => {
						setShowUpcoming(prev => !prev);
					}}
				>
					Upcoming ({upcomingSponsorsLength})
				</ChipButton>
				<ChipButton
					isActive={showExpired}
					onPress={() => {
						setShowExpired(prev => !prev);
					}}
				>
					Expired ({expiredSponsorsLength})
				</ChipButton>
			</div>
			{healthSponsorships?.filter(filterSponsors)?.map((sponsorship, index) => (
				<HealthSponsorshipOverview
					key={sponsorship.id}
					sponsorship={sponsorship}
					index={index + (defaultSponsorship ? 1 : 0)}
					isInternationalConfigsFlagOn={isInternationalConfigsFlagOn}
				/>
			))}
		</>
	);
}

function HealthSponsorships({
	partnerId,
	isDependentGroup = false,
	onFocus,
}: {
	partnerId: string;
	isDependentGroup?: boolean;
	onFocus: () => void;
}): ReactElement {
	const { data: healthSponsorships, loading } = useHealthSponsorships(partnerId, isDependentGroup);
	const [hasValidPermissions, actions] = usePermissions();
	const {
		data: flagValues,
		error: flagError,
		loading: flagLoading,
	} = useFeatureFlags(INTERNATIONAL_CONFIGS_FEATURE_FLAG);

	const isInternationalConfigsFlagOn =
		!flagLoading && !flagError && flagValues?.[INTERNATIONAL_CONFIGS_FEATURE_FLAG] === true;

	const allowedDependentGroupLength = isInternationalConfigsFlagOn ? ALLOWED_COUNTRIES.length : 1;

	const shouldShowCreateButton =
		!isDependentGroup ||
		(isDependentGroup &&
			!loading &&
			allowedDependentGroupLength &&
			allowedDependentGroupLength > (healthSponsorships?.length || 0));

	return (
		<Card onFocus={onFocus} onClick={onFocus}>
			<CellTitle>
				{isDependentGroup ? 'Friends & Family Sponsor Group Configurations' : 'Sponsor Group Configurations'}
			</CellTitle>
			{!healthSponsorships && loading ? (
				<Loader />
			) : isInternationalConfigsFlagOn && !isDependentGroup ? (
				<HealthSponsorshipsInner
					healthSponsorships={healthSponsorships ?? []}
					isInternationalConfigsFlagOn={isInternationalConfigsFlagOn}
				/>
			) : (
				healthSponsorships?.map((sponsorship, index) => (
					<HealthSponsorshipOverview
						key={sponsorship.id}
						sponsorship={sponsorship}
						index={index}
						isInternationalConfigsFlagOn={isInternationalConfigsFlagOn}
					/>
				))
			)}
			{hasValidPermissions('health_sponsorship_display_name', [actions.CREATE]) && shouldShowCreateButton && (
				<PrimaryButton
					href={`/${partnerId}/account/health-sponsorships${
						isDependentGroup ? '?isDependentGroup=true' : ''
					}`}
				>
					Create New Sponsor Group
				</PrimaryButton>
			)}
		</Card>
	);
}

export default HealthSponsorships;
